import {Link} from 'react-router-dom';
import Staff1 from '../static/Staff/staff (1).jpeg';
import Staff2 from '../static/Staff/staff (2).jpeg';
import Staff3 from '../static/Staff/staff (3).jpeg';
import Staff4 from '../static/Staff/staff (4).jpeg';
import Staff5 from '../static/Staff/staff (5).jpeg';
import Staff6 from '../static/Staff/staff (6).jpeg';
import Staff7 from '../static/Staff/staff (7).jpeg';
import Staff8 from '../static/Staff/staff (8).jpeg';
import Staff9 from '../static/Staff/staff (9).jpeg';
import Staff10 from '../static/Staff/staff (10).jpeg';
import Staff11 from '../static/Staff/staff (11).jpeg';
import Staff12 from '../static/Staff/staff (12).jpeg';
import Staff13 from '../static/Staff/staff (13).jpeg';
import Staff14 from '../static/Staff/staff (14).jpeg';
import Staff15 from '../static/Staff/staff (15).jpeg';
import Staff16 from '../static/Staff/staff (16).jpeg';
import Staff17 from '../static/Staff/staff (17).jpeg';
import Staff18 from '../static/Staff/staff (18).jpeg';
import Staff19 from '../static/Staff/staff (19).jpeg';
import Staff20 from '../static/Staff/staff (20).jpeg';
import Staff21 from '../static/Staff/staff (21).jpeg';
import Staff22 from '../static/Staff/staff (22).jpeg';
import Staff23 from '../static/Staff/staff (23).jpeg';
import Staff24 from '../static/Staff/staff (24).jpeg';
 
const Contact = ()=>{
	return(
		<>
			<div className="container text-center p-4 text-white" style={{background:"#3c297c"}}>
				<h1><b>Our Team</b></h1>
			</div>
			<br/><br/>
<div className="container" style={{paddingTop:"50px",paddingBottom:"50px",textAlign:"center"}}>
				<h1 style={{paddingTop:"60px",paddingBottom:"0px"}}>Our Staff</h1>
				<span style={{backgroundColor:"#808080",display:"inline-block",width:"50px",height:"5px",marginBottom:"50px"}}> </span>
				<div className="row staff">
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff1} width="100%" /> <span>Priyanka Sirohi</span> </div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff2} width="100%" /><span>Anuj	Regmi</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff3} width="100%" /><span>Priya Gautam</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff4}  width="100%"/><span>Manju Saini</span></div>
					
					
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff5} width="100%" /> <span>Meenakshi Yadav</span> </div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff6} width="100%" /><span>Anubhuti Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff7} width="100%" /><span>Rekha Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff8}  width="100%"/><span>Anjana Ma'am</span></div>
					
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff9} width="100%" /> <span>Arti Singh</span> </div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff10} width="100%" /><span>Ritu Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff11} width="100%" /><span>Yogita Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff12}  width="100%"/><span>Awantika Chaurasiya</span></div>
					
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff13} width="100%" /> <span>Neetu Raghav</span> </div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff14} width="100%" /><span>Sushma Sharma</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff15} width="100%" /><span>Shimla Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff16}  width="100%"/><span>Geeta Bhagel</span></div>
					
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff17} width="100%" /> <span>Shobha Prajapati</span> </div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff18} width="100%" /><span>Manisha Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff19} width="100%" /><span>Poona Negi</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff20}  width="100%"/><span>Nitin Kumar</span></div>
					
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff21} width="100%" /> <span>Sonali Ma'am</span> </div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff22} width="100%" /><span>Pinki Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff23} width="100%" /><span>Krishna Ma'am</span></div>
					<div className="col-sm-3"><img className="img-thumbnail" src={Staff24}  width="100%"/><span>Anita Singh</span></div>
				</div>
			</div>
		</>
	);
} 
export default Contact;